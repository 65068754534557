<template>
    <div v-if="load">
        <v-container>
            <Dispenser/>
        </v-container>
    </div>
</template>
<script>
    import Dispenser from '../components/Dispenser.vue'
    import axios  from 'axios'
    export default{
        name: 'Dispensers',
        data: () => ({
            load: false
        }),
        components: {
            Dispenser
        },
        mounted(){
            this.validUser()
        },
        methods: {
            validUser(){
                let data = {
                    'opcion': '7',
                    'username': localStorage.getItem('username'),
                    'jwt_token': localStorage.getItem('token')
                }
                axios.post(this.$pathAPI, data)
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        if(datos["mensaje"] == "Usuario no autorizado"){
                            localStorage.removeItem('token')
                            localStorage.removeItem('username')
                            localStorage.clear()
                            window.location.reload();
                            this.$router.push('/');
                        }else{
                            this.load = true
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!'});
                })
            }
        }
    }
</script>